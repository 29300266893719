// import "./src/static/styles/variables.scss";
import "./src/static/styles/app.scss";

import AOS from "aos";
import "aos/dist/aos.css";

export const onClientEntry = () => {
  AOS.init({
    duration: 1200,
  });
};

export const onRouteUpdate = () => {
  AOS.refresh();
};
